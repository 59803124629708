/**
 * Ensure overlay visible.
 */
.logged-in {
  #toolbar {
    z-index: 2000;
  }
  #overlay-container,
  .overlay-modal-background,
  .overlay-element {
    z-index: 1090 !important;
  }
}

body.logged-in.toolbar {
  margin-top: 25px;
}