#search-modal {
  overflow-y: hidden;
  
  &.in {
    .modal-dialog {
      transform: translateY(0);
    }
  }
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 1;
    transform: translateY(100%);
    transition: transform 0.4s ease-out;
    background-color: $uikit-gray-100;

    .modal-content {
      height: auto;
      min-height: 100%;
    }

    .modal-header {
      padding-bottom: 40px;

      .close {
        color: $text-color;
        font-size: 2.5rem;
        right: 89%;
        top: 20px;
      }
    }

    .modal-body {
      padding-top: 20px;

      #search-form {
        max-width: none;

        form {
          display: flex;
          flex-direction: column;
          justify-items: stretch;
        }

        .form-group {
          display: flex;
          flex-direction: row;
          align-items: center;  
          margin-bottom: prem($uikit-spacing-4);
          padding: prem($uikit-spacing-4) prem($uikit-spacing-8);
          background: $input-bg;
          border-radius: prem(50px);
          box-shadow: 0px 0px 2px 0px #4F5E711F,
                      0px 2px 4px 0px #4F5E711C,
                      0px 4px 8px 0px #4F5E711A;


          input {
            width: 100%;
            font-size: prem($uikit-body-font-size-large);
            font-weight: 500;
            margin-left: prem($uikit-spacing-4);
            padding: 0;
            border: 0 none;
            border-radius: 0;
            box-shadow: none;
          }
          .mat-icon {
            font-size: prem(30px);
            font-weight: 500;
            text-align: center;
          }
        }
      }
    }
  }
}