@import '_core';

body {
  &.registered {
    [data-mj-session],
    .cc-candidate-unlogged {
      display: none;
    }
  }
  // reset font family for old content containing font family definition at element level
  div, p, h1, h2, h3, h4, h5, h6 {
    font-family: inherit !important;
  }
}
