.modal-dialog {
  .modal-header {
    .modal-subtitle {
      color: $brand-primary;
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  .form-group {
    text-align: left;
  }

  .cgu {
    color: $gray;
    margin-bottom: 0;
    font-size: $font-size-small;
  }
}