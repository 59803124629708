@import "meteojob-theme/lib/skins/_default/sass/misc/functions";
@import "_variables";
@import "meteojob-theme/lib/skins/_default/sass/misc/mixins";
@import "meteojob-app-banner/lib/app-banner";

body.company {
  #main{
    padding-top: $header-top-height + $grid-gutter-width;
  }
}

@import 'layout/**/*';
@import 'components/**/*';

.header-recruiter-zone {
  > a {
    color: #fff;
  }

  .dropdown-toggle {
    margin-left: $spacing-lg;
  }

  img {
    height: 35px;
  }

  .dropdown-menu {
    @include box-arrow(right);
    right: 0;
    left: initial;
  }
}

.logged-in .action-links a {
  color:#fff;
}

.mj-raw .logged-in .action-links a {
  color:$brand-primary;
}

.input-group {
  .btn-loader {
    position: absolute;
    background: transparent;
    left: -100%;
    right: 0;
    border: 1px solid transparent;
    pointer-events: none;

    .fa-check {
      color: $brand-success;
    }

    .fa-times, .fa-ban, .fa-exclamation-circle, .fa-exclamation-triangle {
      color: $brand-danger;
    }
  }
}

#breadcrumb {
  padding: $spacing-base;
  color: $text-color;
  font-weight: 600;
  a,
  .cc-a-like {
    color: $text-color;
  }
}

strong.highlighted {
  font-weight: normal;
}

.item-list a {
  color: inherit;
}

#support-btn {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  border-radius: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #FFCC00;
  border-color: #FFCC00;
}