.mj-block {
  .item-list ul {
    li {
      margin-left: 0;
      list-style: none;
      a {
        color: $text-color;
      }
    }
  }
  &.mj-block-primary {
    background-color: $brand-primary;
    color: #fff;

    h2 {
      color: #fff;
    }

    .btn-ghost-primary {
      background-color: #fff;
      &:hover {
        color: #aaa;
      }
    }

  }
}
