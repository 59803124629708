ul.pager {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  list-style: inline;  
  display: block;
  margin-top: 10px;

  li a, li span {
    background-color: transparent !important;
    border: 0;
    padding: $spacing-xs $spacing-sm;
    position: relative;
    float: left;
    line-height: $line-height-computed;
    margin-left: -1px;
  }

  li {
    display: inline;
  }

  // Active
  li span {
    color: $brand-primary;
    font-weight: bold;

    &.hover {
      color: $brand-primary;
      background-color: transparent;
      cursor: default;
    }
  }

  li a {
    color: $gray-light;
    float: none;
  }
  li:hover a {
    font-weight: bold;
    color: $text-color;
    text-decoration: underline;
  }

}

.pager-fast {
  font-size: 13px;
}
.item-list {
  .pager li {
    padding: .4em;
  }

  .pager-fast li {
    padding: .2em;

    &.pager-ellipsis {
      padding: 0;
    }
  }
}
