body.has-popin {
  #main {
    padding-top: 0;
  }
}

#mj-popin-wrapper {
  position: relative;

  #mj-image-wrapper {
    position: absolute;
    background-size: 100%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    
    @media (max-width: $screen-md) {
      background-position: center 60px;
      background-color: $brand-primary;
    }
  }

  #mj-popin {
    position: relative;
    min-height: 380px;
    margin: 0;
    padding: 60px 0 84px;
    box-sizing: content-box;

    .btnXLarge {
      color: $uikit-neutral-white;
      background-color: $brand-primary;
      border-color: $brand-primary;

      &[disabled],
      &:focus,
      &:hover {
        background-color: darken($brand-primary, 10%);
            border-color: darken($brand-primary, 25%);
      }

      .badge {
        color: $btn-info-bg;
        background-color: $btn-info-color;
      }
    }

    .views-field-body,
    .field-name-field-teaser {
      margin: 6% 0;
    }

    .field-name-field-referenced-logos .field-items .field-item {
      display: inline-block;
      padding: 5px;
      text-align: center;
    }

    @media (max-width: $screen-md) {
      min-height: 0;
      padding-bottom: 240px !important; 
    }
    @media (max-width: $screen-xs) {
      padding-bottom: 255px !important; 
    }
  }

  .header-search-form {
    .btn-primary-alt {
      color: $uikit-neutral-white;
      background-color: $brand-primary;
      border-color: $brand-primary;

      &[disabled],
      &:focus,
      &:hover {
        background-color: darken($brand-primary, 10%);
            border-color: darken($brand-primary, 25%);
      }
    }
  }
}