/**
 * UI Library exceptions
 */
html.mj-raw {
  background: #fff;

  body.company.logged {
    #header-top-container {
      border-bottom: 0;
    }
    #header.has-scroll.scrolling .header-logo,
    #header .header-logo {
      line-height: inherit;
    }
  }

  body:not(.company) {
    #header {
      &.has-scroll.scrolling {
        background-color: $header-scrolling-bg;
        #header-top-container {
          display: block;
          height: $header-top-height !important; 
        }
      }
    }
  }

  .mj-wrapper {
    position: relative;
  }

  .mj-block {
    margin: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    border: none;
  }

  //== Search form
  #search-form {
    .dropdown-menu {
      min-width: 100%;
      z-index: 10000;
    }

    .search-form-submit-responsive {
      width: 100%;
    }

    .twitter-typeahead > input, .bootstrap-select {
      border: 0;
      box-shadow: $shadow;
      border-radius: 25px;

      &.home-page {
        padding-left: 45px;
        box-shadow: none;
        @media (min-width: 992px) {
          padding-left: 30px !important;;
          border-radius: 0 !important;;
        }
      }
    }

    div > i {
      position: absolute;
    }

    i.icon {
      padding-top: 9px;
      z-index: 10;
      font-size: 1.3rem;
      padding-left: 15px;
      @media (min-width: 992px) {
        padding-left: 0;
      }
    }

    .bootstrap-select .dropdown-toggle {
      border-color: transparent;
      border-radius: 25px;
    }

    .btn-primary-alt {
      padding: 6px 12px;
      font-size: .875rem;
      border: 1px solid transparent;
    }

    @media(min-width: $screen-md-min) {
      .search-form-submit {
        display: inline-block;
      }

      .inputs-wrapper {
        margin-right: $spacing-xs + 38px;

        // Reduce grid gutter with
        .row {
          margin: 0;
          > div {
            padding: 0 $spacing-xs 0 0;
            &:last-child {
              padding: 0;
            }
          }
        }

        .query-column .tt-menu {
          left: 1px !important; // Avoid border overlay ugly effect
        }

        .form-group {
          margin-bottom: 0;
        }
      }

      .search-form-submit-responsive {
        display: none;
      }
    }

    @at-root .node-type-home-page #search-form {
      /* max-width: 920px; */
      margin: 0 auto;
    }

    @at-root .node-type-portail-generique, .cc-page-index-seo, .section-offres-emplois-cadre {
      h1, .h1 {
        color: #000;
        font-size: 2.2rem;
      }
      .cc-seo-section-container {
        &:nth-child(2n+1) {
          background: #F0F4F7;
        }
        h1, .h1 {
          margin-bottom: 20px;
        }
      }
      #search-form {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(43, 173, 244, .7);
        padding: 15px;

        @media(max-width: $screen-sm-max) {
          background: rgba(0, 0, 0, .8);
          #portal-title {
            font-size: $font-size-h2;

            span {
              display: inline-block;
              margin-right: 5px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
  //== End Search form

  .cc-page-index-seo,
  .page-node:not(.node-type-portail-generique) {
    #breadcrumb {
      display: none;
    }
  }

  #main {
    padding-top: 0;

    & > .top-level-container {
      max-width: none;
    }

    #block-system-main {
      &.mj-block {
        padding: 0;
      }
      .mj-container {
        max-width: 1170px;
        margin: 0 auto;
      }
    }
  }

  .mj-column-content-wrapper {
    padding: 0;
  }

  .title {
    padding: 15px 20px 40px 20px;

    h1, h2 {
      color: $brand-primary;
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  .main-section {
    padding: 30px 0 0;

    &:last-child {
      padding-bottom: 30px;
    }
  }

  .links-block {
    padding: 15px 20px;
    background-color: #f8f8f8;

    a {
      color: #000;
      line-height: 1.8em;
    }

    .views-view-grid {
      width: 100%;
    }
  }
  #hp_medias {
    a:hover {text-decoration:none;}
    a.brand-quaternary {
      text-transform: uppercase;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
}